import React, { FC, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';

import { Layout } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { billingModel, EffectToAddType } from '@/entities/billing';
import { PATHS } from '@/shared/config';
import { shouldAskAdditionalInfo } from '@/shared/lib/shouldAskAdditionalData';
import { AdditionalDataForm } from '@/features/additionalDataForm';
import {
  GroupNamesDefaultMessage,
  lifeTimeMonthDefaultMessages,
  ServiceNamesDefaultMessage,
} from '@/shared/config/i18n';
import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';

import styles from './AddSubscription.module.scss';

const { Title } = Typography;
const { Panel } = Collapse;

type AddSubscriptionFormType = {
  autoRenewal: boolean;
  subscriptionID?: string;
  lifeTimeMonths?: number;
};

const selectNewOrderLoadingState =
  billingModel.selectors.getLoadingStateSelector('newOrder');

export const AddSubscription: FC = () => {
  const [isAdditionalFormOpen, setIsAdditionalFormOpen] =
    useState<boolean>(false);

  const { organizationID, userID } =
    useParams<{ organizationID: string; userID: string }>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const organization = useAppSelector(
    organizationModel.selectors.selectOrganization,
  );
  const currentUser = useAppSelector(userModel.selectors.selectCurrentUser);
  const subscriptions = useAppSelector(
    billingModel.selectors.selectAvailableSubscriptions,
  );

  const newOrderLoadingState = useAppSelector(selectNewOrderLoadingState);

  const {
    ID: accountID,
    BillingZones,
    BillingInformation,
  } = useAppSelector(billingModel.selectors.selectAccountInfo);

  const BillingZone = BillingZones?.at(0);

  const { formatMessage } = useIntl();
  // TODO [2|l] devtools throw warning that form doesnt connect to any Form component but its connected. Need to solve this issue
  const [form] = Form.useForm<AddSubscriptionFormType>();

  Form.useWatch('subscriptionID', form);

  const selectedSubscriptionID = form.getFieldValue('subscriptionID');

  const currentSubscription = subscriptions.find(
    ({ SKU }) => SKU === selectedSubscriptionID,
  );

  const onFinish = async () => {
    const isAutoRenewal = form.getFieldValue('autoRenewal');

    const effectToAdd: EffectToAddType = {
      case: 'SubscriptionAdd',
      value: {
        Subscription: currentSubscription,
        IsAutoRenewal: currentSubscription.Name.includes('KOL')
          ? false
          : isAutoRenewal,
      },
    };

    if (
      shouldAskAdditionalInfo(BillingZone, BillingInformation) &&
      Number(currentSubscription?.Price ?? 0) > 0
    ) {
      dispatch(billingModel.actions.setEffectToAdd(effectToAdd));

      setIsAdditionalFormOpen(true);
    } else {
      try {
        dispatch(
          billingModel.actions.setLoading({
            loaderKey: 'newOrder',
            loaderState: 'pending',
          }),
        );

        await dispatch(
          billingModel.thunks.createOrder({
            AccountID: accountID,
            Effect: effectToAdd,
          }),
        );

        navigate(generatePath(PATHS.organization, { organizationID, userID }));
      } catch {
        dispatch(
          billingModel.actions.setLoading({
            loaderKey: 'newOrder',
            loaderState: 'failed',
          }),
        );
      } finally {
        dispatch(
          billingModel.actions.setLoading({
            loaderKey: 'newOrder',
            loaderState: 'idle',
          }),
        );
      }
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      const { AvailableProducts } = await dispatch(
        billingModel.thunks.getAvailableProducts({
          Condition: {
            case: 'ByAccountID',
            value: accountID,
          },
        }),
      ).unwrap();

      dispatch(billingModel.actions.setAvailableProducts(AvailableProducts));
    };

    if (accountID) {
      getProducts();
    }
  }, [accountID]);

  return (
    <Layout.Content>
      <Breadcrumb
        className="p2"
        style={{ padding: '24px 0' }}
        items={[
          {
            title: (
              <Link className={styles.breadcrumb} to={generatePath(PATHS.main)}>
                <FormattedMessage id="breadcrumb.home" defaultMessage="Home" />
              </Link>
            ),
          },
          {
            title: currentUser && (
              <Link
                className={styles.breadcrumb}
                to={generatePath(PATHS.user, {
                  userID,
                })}
              >
                {`${currentUser?.PersonalData?.FirstName ?? ''} ${
                  currentUser?.PersonalData?.LastName ?? ''
                }`}
              </Link>
            ),
          },
          {
            title: (
              <Link
                className={styles.breadcrumb}
                to={generatePath(PATHS.organization, {
                  organizationID,
                  userID,
                })}
              >
                {organization?.Name ?? ''}
              </Link>
            ),
          },
          {
            title: (
              <FormattedMessage
                id="newSubscriptionPage.title"
                defaultMessage="New subscription"
              />
            ),
          },
        ]}
      />

      <Form
        form={form}
        initialValues={{
          autoRenewal: true,
        }}
        name="add-subscription"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={(values) => {
          if (values?.subscriptionID) {
            form.setFieldsValue({ lifeTimeMonths: undefined });
          }
        }}
      >
        <Row gutter={32}>
          <Col span={12}>
            {/* Step 1 */}
            <Title level={4}>
              <FormattedMessage
                id="subscriptionForm.stepOne"
                defaultMessage="Select subscription"
              />
            </Title>

            <Form.Item
              name="subscriptionID"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'forms.required',
                    defaultMessage: 'Field is required',
                  }),
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  {subscriptions.map(
                    ({
                      SKU,
                      Name,
                      Price,
                      Currency,
                      Kind: subscriptionKind,
                      Services,
                    }) => (
                      <Radio key={SKU} value={SKU}>
                        <Space direction="horizontal">
                          <Collapse expandIcon={() => null} size="small">
                            <Panel
                              key={SKU}
                              header={
                                <Title level={5} style={{ marginBottom: 0 }}>
                                  <>
                                    {Name}
                                    {' | '}
                                    <FormattedNumber
                                      maximumFractionDigits={0}
                                      value={Number(Price ?? '0')}
                                      // eslint-disable-next-line react/style-prop-object
                                      style="currency"
                                      currencyDisplay="narrowSymbol"
                                      currency={Currency}
                                    />
                                    {' | '}
                                    {subscriptionKind.case === 'Subscription' &&
                                      (subscriptionKind.value.LifeTimeDays > 0
                                        ? formatMessage(
                                            {
                                              id: 'product.lifetime.days',
                                              defaultMessage:
                                                '{days, plural, =0 {0 days} one {One day} other {{days} days}}',
                                            },
                                            {
                                              days: subscriptionKind.value
                                                .LifeTimeDays,
                                            },
                                          )
                                        : formatMessage(
                                            lifeTimeMonthDefaultMessages[
                                              subscriptionKind.value
                                                .LifeTimeMonths
                                            ],
                                          ))}
                                  </>
                                </Title>
                              }
                            >
                              <ul>
                                {Services?.map(({ Types, Group, Kind }) => {
                                  const amount =
                                    Kind.case === 'Countable'
                                      ? Number(Kind.value.Limit)
                                      : 0;

                                  return (
                                    <li key={`${Types.toString()}`}>
                                      {Types.length > 1
                                        ? formatMessage(
                                            GroupNamesDefaultMessage[Group],
                                            {
                                              amount,
                                            },
                                          )
                                        : formatMessage(
                                            ServiceNamesDefaultMessage[
                                              Types.at(0)
                                            ],
                                            {
                                              amount,
                                            },
                                          )}
                                      {Types.length > 1 && (
                                        <ul>
                                          {Types.map((type) => (
                                            <li>
                                              {formatMessage(
                                                ServiceNamesDefaultMessage[
                                                  type
                                                ],
                                                { amount: 0 },
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </Panel>
                          </Collapse>
                        </Space>
                      </Radio>
                    ),
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>

            {/* Step 2 */}
            {/* TODO: add DiscountField here when backend implement discount */}

            {/* Step 3 */}
            <Title level={4}>
              <FormattedMessage
                id="subscriptionForm.stepTwo"
                defaultMessage="Auto-renewal:"
              />

              {subscriptions.length > 0 && (
                <Form.Item
                  name="autoRenewal"
                  valuePropName="checked"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Checkbox style={{ fontWeight: 400 }}>
                    {formatMessage({
                      id: 'subscriptionForm.autoRenewal.label',
                      defaultMessage: 'Enable auto-renewal of subscription',
                    })}
                  </Checkbox>
                </Form.Item>
              )}
            </Title>
          </Col>
        </Row>

        <Button
          type="primary"
          htmlType="submit"
          loading={newOrderLoadingState === 'pending'}
        >
          <span>
            <FormattedMessage
              id="subscriptionForm.addNewSubscription"
              defaultMessage="Add new subscription"
            />
          </span>
        </Button>
      </Form>

      <AdditionalDataForm
        isOpen={isAdditionalFormOpen}
        onCloseHandler={() => setIsAdditionalFormOpen(false)}
      />
    </Layout.Content>
  );
};
